<template>
  <b-modal
    id="compose-mail"
    :visible="showModal"
    title="Compose Email"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
  >
          <template #footer>
        </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BAvatar,
  BDropdownDivider,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style>
</style>